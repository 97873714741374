<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import useEventBus, { EVENTS } from '~/composables/useEventBus'
import useChatStore from '~/stores/useChatStore'
import useApiFetch from '~/composables/useApiFetch'

const chatStore = useChatStore()
const newChatBus = useEventBus({ name: EVENTS.NEW_CHAT })
const router = useRouter()

const handleNewChat = async (options: any) => {
  const chat = options.chat || {}
  const prompt = options.prompt || {}

  let url = options.url || '/chats/all'

  const body: any = {
    ...chat,
  }

  const { data } = await useApiFetch('/chats', {
    method: 'POST',
    body,
  })

  url = `${url}/${data.value.id}`
  if (prompt) url = `${url}?prompt_id=${prompt.id}`

  chatStore.add({ chat: data.value })

  router.push(url)
}

newChatBus.on(handleNewChat)
</script>
