// import '@formkit/themes/genesis'
// https://github.com/formkit/formkit/blob/master/packages/themes/src/tailwindcss/genesis/index.ts
import { generateClasses } from '@formkit/themes'
import { DefaultConfigOptions } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'

const pro = createProPlugin('fk-384fd7979d', inputs)

const baseClasses = {
  button: {
    label: 'block text-sm font-medium text-gray-700 mb-1',
    input:
      'form-input block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md',
    message: 'text-red-500 mt-1 text-xs',
  },
  email: {
    label: 'block text-sm font-medium text-gray-700 mb-1',
    input:
      'form-input block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md',
    message: 'text-red-500 mt-1 text-xs',
  },
  textarea: {
    label: 'block text-sm font-medium text-gray-700 mb-1',
    input:
      'form-textarea block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md',
    message: 'text-red-500 mt-1 text-xs',
    help: 'text-gray-500 text-xs mt-1',
  },
  password: {
    label: 'block text-sm font-medium text-gray-700 mb-1',
    input:
      'form-input block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md placeholder-gray-300',
    message: 'text-red-500 mt-1 text-xs',
    help: 'text-gray-500 text-xs mt-0.5',
  },
  text: {
    label: 'block text-sm font-medium text-gray-700 mb-1',
    input:
      'form-input block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md placeholder-gray-300',
    message: 'text-red-500 mt-1 text-xs',
    help: 'text-gray-500 text-xs mt-0.5',
  },
  search: {
    label: 'block text-sm font-medium text-gray-700 mb-1',
    input:
      'form-input block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md',
    suffixIcon: 'text-red-500 w-6 h-6 block relative top-0',
  },
  select: {
    label: 'block text-sm font-medium text-gray-700 mb-1',
    input:
      'form-select block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md',
    selectIcon:
      'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none',
    message: 'text-red-500 mt-1 text-xs',
  },
  url: {
    label: 'block text-sm font-medium text-gray-700',
    inner: 'mt-1',
    input:
      'form-input block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md placeholder-gray-300',
    message: 'text-red-500 mt-1 text-xs',
  },
}

export default {
  plugins: [pro],
  config: {
    classes: generateClasses(baseClasses),
  },
}
