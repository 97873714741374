import { defineNuxtPlugin } from '#app'
        import { plugin, defaultConfig, ssrComplete } from '@formkit/vue'
        import { resetCount } from '@formkit/core'

        import importedConfig from '/opt/render/project/src/formkit.config.ts'

        export default defineNuxtPlugin((nuxtApp) => {
          const config = defaultConfig(typeof importedConfig === 'function' ? importedConfig() : importedConfig)
          nuxtApp.hook('app:rendered', (renderContext) => {
            resetCount()
            ssrComplete(nuxtApp.vueApp)
          })
          nuxtApp.vueApp.use(plugin, config)

        })
        