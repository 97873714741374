import { defineStore } from 'pinia'
import useApiFetch from '~/composables/useApiFetch'
import { AVAILABLE_AI_MODELS } from '~/constants'
import type { Folder, Chat, FolderPartial } from '~/types'
import useAuthStore from './useAuthStore'

interface iChatTag {
  value: string
  count: number
}

interface iState {
  folders: {
    data: FolderPartial[]
    pending: boolean
  }
  chats: {
    data: Chat[]
    pending: boolean
  }
  chatTags: {
    data: iChatTag[]
    pending: boolean
  }
  openAiModels: {
    data: any[]
    pending: boolean
  }
}

const useChatStore = defineStore('chats', {
  state: () => {
    const state: iState = {
      folders: { data: [], pending: true },
      chats: { data: [], pending: true },
      chatTags: { data: [], pending: true },
      openAiModels: { data: [], pending: true },
    }

    return state
  },
  getters: {
    getFolderById: (state) => {
      return (folderId: string) => {
        return state.folders.data.find(
          (folder) => folder.id === parseInt(folderId)
        )
      }
    },
    getAvailableModels: (state) => {
      const authStore = useAuthStore()
      const models = Object.keys(AVAILABLE_AI_MODELS).map(
        // @ts-ignore
        (key) => AVAILABLE_AI_MODELS[key]
      )

      return computed(() => {
        if (!state.openAiModels.data) return []

        return models
          .filter(
            (model) =>
              model.company !== 'anthropic' ||
              (model.company === 'anthropic' && authStore.can('useAnthropic'))
          )
          .filter(
            (model) =>
              model.company !== 'google' ||
              (model.company === 'google' && authStore.can('useGoogle'))
          )
          .filter(
            (model) =>
              model.company !== 'openai' ||
              (model.company === 'openai' && authStore.can('useOpenAi'))
          )

        return models
      })
    },
    getChatById: (state) => {
      return (chatId: string) => {
        return computed(() => {
          return state.chats.data.find((chat) => chat.id === chatId)
        })
      }
    },
    searchChats: (state) => {
      const stringMatch = (item: string = '', term: string = '') => {
        return (
          (item || '').toLowerCase().indexOf((term || '').toLowerCase()) > -1
        )
      }

      const contentMatch = (chat: Chat, term: string = '') => {
        return stringMatch(chat.title, term) || stringMatch(chat.content, term)
      }

      const tagMatch = (chat: Chat, tag: string = '') => {
        return chat.tags.includes(tag)
      }

      return ({ term = '', tag = '' }: { term: string; tag: string }) => {
        return computed(() =>
          state.chats.data.filter((chat) => {
            if (term.length > 0 && tag.length > 0) {
              return contentMatch(chat, term) && tagMatch(chat, tag)
            } else if (term.length > 0) {
              return contentMatch(chat, term)
            } else if (tag.length > 0) {
              return tagMatch(chat, tag)
            } else {
              return true
            }
          })
        )
      }
    },
  },
  actions: {
    init({ folders, chats }: iState) {
      this.folders = folders
      this.chats = chats
    },

    // ------------------------------------------
    // CHAT TAGS
    // ------------------------------------------

    async fetchTags() {
      const { data } = await useApiFetch('/chats/tags')
      this.setChatTags({ chatTags: data.value })
    },

    setChatTags({ chatTags }: { chatTags: { data: iChatTag[] } }) {
      this.chatTags = { ...chatTags, pending: false }
    },

    // ------------------------------------------
    // CHATS
    // ------------------------------------------

    add({ chat }: { chat: Chat }) {
      this.chats.data.push(chat)
    },

    async fetch() {
      const { data } = await useApiFetch('/chats')

      this.set({ chats: data.value })
    },

    async fetchOpenAiModels() {
      const { data } = await useApiFetch('/openai/models')
      this.openAiModels = { data: data.value.data, pending: false }
    },

    set({ chats }: { chats: { data: Chat[] } }) {
      this.chats = { ...chats, pending: false }
    },

    remove({ chatId }: { chatId: string }) {
      this.chats.data = this.chats.data.filter((chat) => chat.id !== chatId)
    },

    update({ chat }: { chat: Chat }) {
      this.chats.data = this.chats.data.map((p) => {
        if (p.id !== chat.id) return p
        return chat
      })
    },
  },
})

export default useChatStore
