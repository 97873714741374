import { useEventBus as useEventBusVueUse } from '@vueuse/core'

export const EVENTS = {
  // PROMPTS
  COPY_PROMPT: 'copy-prompt',
  CREATE_CHAT: 'create-chat',
  CREATE_FOLDER: 'create-folder',
  CREATE_PROMPT: 'create-prompt',
  DELETE_CHAT: 'delete-chat',
  DELETE_FOLDER: 'delete-folder',
  DELETE_PROMPT: 'delete-prompt',
  EDIT_FOLDER: 'edit-folder',
  EDIT_PROMPT: 'edit-prompt',
  FINISH_CHAT_STEAMING: 'finish-chat-streaming',
  NEW_CHAT: 'new-chat',
  NEW_PROMPT: 'new-prompt',
  IMPORT_PROMPTS: 'import-prompts',
  SIGNUP: 'signup',
  SEARCH: 'search',
  SELECT_FOLDER: 'select-folder',
  SELECT_PROMPT: 'select-prompt',
  SHARE_FOLDER: 'share-folder',
  SHARE_PROMPT: 'share-prompt',
  TOGGLE_SIDEBAR: 'toggle-sidebar',
  TRACK: 'track',
}

const useEventBus = <T = any>({ name }: { name: string }) => {
  const bus = useEventBusVueUse<T>(name)

  return {
    emit: bus.emit,
    on: bus.on,
    off: bus.off,
    reset: bus.reset,
  }
}

export default useEventBus
