import type { AxiosResponse } from 'axios'
import type { UserPartial, OrganizationPartial } from '~/types'
import { defineStore } from 'pinia'
import useApiFetch from '~/composables/useApiFetch'

interface iPermissions {
  isAdmin?: boolean
}

type iPermission =
  | 'addUsers'
  | 'beta'
  | 'chat'
  | 'owner'
  | 'ownerOrAdmin'
  | 'useAnthropic'
  | 'useGoogle'
  | 'useOpenAi'

const useAuthStore = defineStore('auth', {
  getters: {
    // simple ACL permissions
    can: (state) => (permission: iPermission) => {
      // @ts-ignore
      const role = state.user?.role || 'user'
      const planId = state.organization?.plan_id
      const settings: any = state.organization?.settings || {}
      const beta = (planId && planId != 'free') || state.organization.beta
      const hasProPlan = planId?.includes('pro')

      const permissions = {
        beta,
        chat: hasProPlan,
        owner: role === 'owner',
        addUsers: planId !== null && planId !== 'free',
        ownerOrAdmin: ['owner', 'admin'].includes(role),
        useAnthropic: hasProPlan && settings.anthropic_api_key,
        useOpenAi: hasProPlan && settings.openai_api_key,
        useGoogle: hasProPlan && settings.google_api_key,
      }

      if (!Object.keys(permissions).includes(permission)) {
        console.error(
          `${role} role - Permission ${permission} is not supported`
        )
        return false
      }

      // @ts-ignore
      return permissions[permission]
    },
    getUserById: (state) => {
      return (userId: string) => {
        return state.users.data.find((user) => user.id === userId)
      }
    },
    getActiveUsers: (state) => {
      // @ts-ignore
      return state.users?.data?.filter((user) => user.status === 'active')
    },
  },
  state: () => {
    const organization: OrganizationPartial = {}
    const permissions: iPermissions = {}
    const subscription: any = {}
    const token: string | null = null
    const user: UserPartial = {}
    const users: { data: UserPartial[]; pending: boolean } = {
      data: [],
      pending: true,
    }

    return {
      authenticated: false,
      token,
      organization,
      subscription,
      permissions,
      user,
      users,
    }
  },
  actions: {
    async fetch() {
      const { data, error } = await useApiFetch('/settings')

      if (!error.value) {
        this.authenticated = true
        this.token = data.value?.token
        this.user = data.value?.user
        this.users = { data: data.value?.users, pending: false }
        this.organization = data.value?.organization
      }
    },
  },
})

export default useAuthStore
