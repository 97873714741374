import revive_payload_client_4sVQNw7RlN from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/render/project/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/render/project/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/render/project/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/opt/render/project/src/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/opt/render/project/src/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/render/project/src/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/render/project/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import notiwind_plugin_client_TO00l29lH2 from "/opt/render/project/src/plugins/notiwind-plugin.client.ts";
import sentry_client_shVUlIjFLk from "/opt/render/project/src/plugins/sentry.client.ts";
import vue_dompurify_html_ER8SW1XOdk from "/opt/render/project/src/plugins/vue-dompurify-html.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_AOuQ1DYzjk,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_UciE0i6zes,
  notiwind_plugin_client_TO00l29lH2,
  sentry_client_shVUlIjFLk,
  vue_dompurify_html_ER8SW1XOdk
]