import useConfig from '~/composables/useConfig'

const isDevelopment = process.env.NODE_ENV === 'development'

export const ACTIVE_CAMPAIGN = {
  DEFAULT_LIST_ID: 1,
  DEFAULT_LIST_STATUS: 1,
  FIELDS: {
    extensionInstalled: {
      id: 1,
    },
    teamSize: {
      id: 2,
    },
    promptTotalCount: {
      id: 3,
    },
    promptShareCount: {
      id: 4,
    },
    folderTotalCount: {
      id: 5,
    },
    folderShareCount: {
      id: 6,
    },
    role: {
      id: 7,
    },
    beta: {
      id: 9,
    },
  },
}

export const COUPONS = {
  'secret-f6s': {
    type: 'trialExtension',
    amount: { unit: 'months', duration: 12 },
  },
}

export const AVAILABLE_AI_MODELS = {
  // ------------------------------
  // ChatGPT
  // ------------------------------
  // https://platform.openai.com/docs/api-reference/chat/create
  'gpt-3.5-turbo': {
    id: 'gpt-3.5-turbo',
    company: 'openai',
    platform: 'OpenAI',
    name: 'GPT-3.5 Turbo',
    description: '',
  },
  'gpt-3.5-turbo-16k': {
    id: 'gpt-3.5-turbo-16k',
    company: 'openai',
    platform: 'OpenAI',
    name: 'GPT-3.5 Turbo 16k',
    description: '',
  },
  'gpt-4-turbo': {
    id: 'gpt-4-turbo',
    company: 'openai',
    platform: 'OpenAI',
    name: 'GPT-4 Turbo',
    description: '',
  },
  // ------------------------------
  // Claude
  // ------------------------------
  'claude-3-haiku-20240307': {
    id: 'claude-3-haiku-20240307',
    company: 'anthropic',
    platform: 'Claude',
    name: 'Claude 3 Haiku',
    description: '',
  },
  'claude-3-sonnet-20240229': {
    id: 'claude-3-sonnet-20240229',
    company: 'anthropic',
    platform: 'Claude',
    name: 'Claude 3 Sonnet',
    description: '',
  },
  'claude-3-opus-20240229': {
    id: 'claude-3-opus-20240229',
    company: 'anthropic',
    platform: 'Claude',
    name: 'Claude 3 Opus',
    description: '',
  },
  // ------------------------------
  // Gemini
  // ------------------------------
  'gemini-pro': {
    id: 'gemini-pro',
    company: 'google',
    platform: 'Gemini',
    name: 'Gemini Pro',
    description: '',
  },
  'gemini-1.5-pro-latest': {
    id: 'gemini-1.5-pro-latest',
    company: 'google',
    platform: 'Gemini',
    name: 'Gemini Pro 1.5',
    description: '',
  },
}

export const EMAIL_TEMPLATES = {
  WELCOME: {
    id: 32166527,
    templateVars: ({ user }: any) => {
      return {
        name: user.name,
      }
    },
  },
  USER_EMAIL_CONFIRMATION: {
    id: 32287281,
    templateVars: ({ user }: any) => {
      const config = useConfig()
      return {
        action_url: config.public.APP_URL + `/users/${user?.id}/verified-email`,
        name: user?.name,
      }
    },
  },
  USER_PASSWORD_RESET: {
    id: 32289982,
    templateVars: ({ user }: any) => {
      const config = useConfig()

      return {
        action_url: `${config.public.APP_URL}/users/${user?.reset_token}/reset-password`,
        name: user?.name,
      }
    },
  },
  USER_INVITE: {
    id: 32166529,
    templateVars: ({ user, invitedUser, invite }: any) => {
      const config = useConfig()
      return {
        action_url:
          config.public.APP_URL + '/users/invite/' + invite?.invite_token,
        invite_sender_name: invitedUser.name || invitedUser.email,
        invite_sender_organization_name: '',
        name: user?.name,
        product_name: 'PromptDrive',
      }
    },
  },
}

export const PRODUCTS = [
  {
    id: 'prompt-library-v1',
    description: 'Prompt Library',
    amount_in_cents: 2900,
  },
]

export const PROMPT_CATEGORIES = {
  chatgpt: 'ChatGPT',
  claude: 'Claude',
  gemini: 'Gemini',
  midjourney: 'Midjourney',
}

export const SUBSCRIPTION_PLANS = [
  {
    id: 'free',
    product: 'free',
    nickname: 'Individual',
    unit_amount: 0,
    description: 'For individual users',
    interval: 'month',
  },
  {
    id: 'free-annual',
    product: 'free',
    nickname: 'Individual',
    unit_amount: 0,
    description: 'For individual users',
    interval: 'year',
  },
  {
    id: 'team-basic',
    product: 'basic',
    stripe_price: isDevelopment
      ? 'price_1NKtHzHK8w5sgeBMVMxC7KMe'
      : 'price_1NcU2kHK8w5sgeBM0dw2gcLB',
    nickname: 'Team (per user)',
    description: 'Invite your team',
    unit_amount: 500,
    interval: 'month',
  },
  {
    id: 'team-basic-annual',
    product: 'basic',
    nickname: 'Team Annual (per user)',
    stripe_price: isDevelopment
      ? 'price_1NKtHzHK8w5sgeBMXciqC440'
      : 'price_1NcU2kHK8w5sgeBMzJK3UdgJ',
    description: 'Invite your team',
    unit_amount: 5000,
    interval: 'year',
  },
  {
    id: 'pro',
    product: 'pro',
    stripe_price: isDevelopment
      ? 'price_1NtZsgHK8w5sgeBMBapkhpEQ'
      : 'price_1NtZs8HK8w5sgeBMKrtarRWk',
    nickname: 'Pro (per user)',
    description: 'Integrated ChatGPT',
    unit_amount: 1000,
    interval: 'month',
  },
  {
    id: 'pro-annual',
    product: 'pro',
    nickname: 'Pro Annual (per user)',
    stripe_price: isDevelopment
      ? 'price_1NtZsgHK8w5sgeBMLDUrYuwR'
      : 'price_1NtZs8HK8w5sgeBM6gxdmSwo',
    description: 'Invite your team',
    unit_amount: 10000,
    interval: 'year',
  },
]
