import { useFetch } from '@vueuse/core'
import useConfig from './useConfig'

const useApiFetch = async (path: string, options: any = { method: 'GET' }) => {
  const config = useConfig()
  const method = options.method || 'GET'
  const url = `${config.public.APP_URL || ''}/api${path}`

  if (method === 'POST') {
    const { data, error, isFetching } = await useFetch(url)
      .post(options.body)
      .json()

    return { data, error, pending: isFetching }
  }

  if (method === 'PUT') {
    const { data, error, isFetching } = await useFetch(url)
      .put(options.body)
      .json()

    return { data, error, pending: isFetching }
  }

  if (method === 'DELETE') {
    const { data, error, isFetching } = await useFetch(url).delete().json()

    return { data, error, pending: isFetching }
  }

  const { data, error, isFetching } = await useFetch(url).get().json()

  return { data, error, pending: isFetching }
}

export default useApiFetch
