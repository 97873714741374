import { default as _91chat_id_93R0hXkkrsE9Meta } from "/opt/render/project/src/modules/chat/pages/chats/[id]/[chat_id].vue?macro=true";
import { default as _91id_93CJ7D8lmJwlMeta } from "/opt/render/project/src/modules/chat/pages/chats/[id].vue?macro=true";
import { default as newzI80gFurdoMeta } from "/opt/render/project/src/modules/chat/pages/chats/new.vue?macro=true";
import { default as chatsxTkpwKc58PMeta } from "/opt/render/project/src/modules/chat/pages/chats.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as commentsW29MFGoMdnMeta } from "/opt/render/project/src/pages/lab/comments.vue?macro=true";
import { default as loginjhbtmcfyq0Meta } from "/opt/render/project/src/pages/login.vue?macro=true";
import { default as privacyinT5lCH87aMeta } from "/opt/render/project/src/pages/privacy.vue?macro=true";
import { default as _91prompt_id_93HB8laHu18tMeta } from "/opt/render/project/src/pages/prompts/folders/[id]/prompts/[prompt_id].vue?macro=true";
import { default as _91id_93xKhBMVAakPMeta } from "/opt/render/project/src/pages/prompts/folders/[id].vue?macro=true";
import { default as foldersP9c2HKCUHUMeta } from "/opt/render/project/src/pages/prompts/folders.vue?macro=true";
import { default as homeBMM83DknTfMeta } from "/opt/render/project/src/pages/prompts/home.vue?macro=true";
import { default as promptsrdi7Es5cXyMeta } from "/opt/render/project/src/pages/prompts.vue?macro=true";
import { default as registeruUya2x0YcpMeta } from "/opt/render/project/src/pages/register.vue?macro=true";
import { default as billingz58L60rCNtMeta } from "/opt/render/project/src/pages/settings/billing.vue?macro=true";
import { default as checkoute1CvOzCAL5Meta } from "/opt/render/project/src/pages/settings/checkout.vue?macro=true";
import { default as importdKt0nECqaZMeta } from "/opt/render/project/src/pages/settings/import.vue?macro=true";
import { default as indexU1KuRoCYHlMeta } from "/opt/render/project/src/pages/settings/index.vue?macro=true";
import { default as payment_45successdW2ITcuz81Meta } from "/opt/render/project/src/pages/settings/payment-success.vue?macro=true";
import { default as prompt_45library9X38GsCQpZMeta } from "/opt/render/project/src/pages/settings/products/prompt-library.vue?macro=true";
import { default as profileWAOixmIgt6Meta } from "/opt/render/project/src/pages/settings/profile.vue?macro=true";
import { default as userspm4R0t5UULMeta } from "/opt/render/project/src/pages/settings/users.vue?macro=true";
import { default as settings0miuIC4XHwMeta } from "/opt/render/project/src/pages/settings.vue?macro=true";
import { default as _91prompt_permalink_93PluQPoDAemMeta } from "/opt/render/project/src/pages/shared/folder-[permalink]/[prompt_permalink].vue?macro=true";
import { default as folder_45_91permalink_93plZqN5YjxlMeta } from "/opt/render/project/src/pages/shared/folder-[permalink].vue?macro=true";
import { default as prompt_45_91permalink_93vr2xV62kTjMeta } from "/opt/render/project/src/pages/shared/prompt-[permalink].vue?macro=true";
import { default as testvaXT3zAa2RMeta } from "/opt/render/project/src/modules/chat/pages/test.vue?macro=true";
import { default as tossCxLO2gLUeMeta } from "/opt/render/project/src/pages/tos.vue?macro=true";
import { default as reset_45password4yDKgoYRpdMeta } from "/opt/render/project/src/pages/users/[id]/reset-password.vue?macro=true";
import { default as verified_45emailuDy2z2w5l5Meta } from "/opt/render/project/src/pages/users/[id]/verified-email.vue?macro=true";
import { default as verify_45emailR7tQT2jEbNMeta } from "/opt/render/project/src/pages/users/[id]/verify-email.vue?macro=true";
import { default as forgot_45password6fk3sYDmaFMeta } from "/opt/render/project/src/pages/users/forgot-password.vue?macro=true";
import { default as _91id_93buGp25npjFMeta } from "/opt/render/project/src/pages/users/invite/[id].vue?macro=true";
export default [
  {
    name: chatsxTkpwKc58PMeta?.name ?? "chats",
    path: chatsxTkpwKc58PMeta?.path ?? "/chats",
    meta: chatsxTkpwKc58PMeta || {},
    alias: chatsxTkpwKc58PMeta?.alias || [],
    redirect: chatsxTkpwKc58PMeta?.redirect,
    component: () => import("/opt/render/project/src/modules/chat/pages/chats.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93CJ7D8lmJwlMeta?.name ?? "chats-id",
    path: _91id_93CJ7D8lmJwlMeta?.path ?? ":id()",
    meta: _91id_93CJ7D8lmJwlMeta || {},
    alias: _91id_93CJ7D8lmJwlMeta?.alias || [],
    redirect: _91id_93CJ7D8lmJwlMeta?.redirect,
    component: () => import("/opt/render/project/src/modules/chat/pages/chats/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91chat_id_93R0hXkkrsE9Meta?.name ?? "chats-id-chat_id",
    path: _91chat_id_93R0hXkkrsE9Meta?.path ?? ":chat_id()",
    meta: _91chat_id_93R0hXkkrsE9Meta || {},
    alias: _91chat_id_93R0hXkkrsE9Meta?.alias || [],
    redirect: _91chat_id_93R0hXkkrsE9Meta?.redirect,
    component: () => import("/opt/render/project/src/modules/chat/pages/chats/[id]/[chat_id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: newzI80gFurdoMeta?.name ?? "chats-new",
    path: newzI80gFurdoMeta?.path ?? "new",
    meta: newzI80gFurdoMeta || {},
    alias: newzI80gFurdoMeta?.alias || [],
    redirect: newzI80gFurdoMeta?.redirect,
    component: () => import("/opt/render/project/src/modules/chat/pages/chats/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexYy8SidDWaAMeta?.name ?? "index",
    path: indexYy8SidDWaAMeta?.path ?? "/",
    meta: indexYy8SidDWaAMeta || {},
    alias: indexYy8SidDWaAMeta?.alias || [],
    redirect: indexYy8SidDWaAMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: commentsW29MFGoMdnMeta?.name ?? "lab-comments",
    path: commentsW29MFGoMdnMeta?.path ?? "/lab/comments",
    meta: commentsW29MFGoMdnMeta || {},
    alias: commentsW29MFGoMdnMeta?.alias || [],
    redirect: commentsW29MFGoMdnMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/lab/comments.vue").then(m => m.default || m)
  },
  {
    name: loginjhbtmcfyq0Meta?.name ?? "login",
    path: loginjhbtmcfyq0Meta?.path ?? "/login",
    meta: loginjhbtmcfyq0Meta || {},
    alias: loginjhbtmcfyq0Meta?.alias || [],
    redirect: loginjhbtmcfyq0Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacyinT5lCH87aMeta?.name ?? "privacy",
    path: privacyinT5lCH87aMeta?.path ?? "/privacy",
    meta: privacyinT5lCH87aMeta || {},
    alias: privacyinT5lCH87aMeta?.alias || [],
    redirect: privacyinT5lCH87aMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: promptsrdi7Es5cXyMeta?.name ?? "prompts",
    path: promptsrdi7Es5cXyMeta?.path ?? "/prompts",
    meta: promptsrdi7Es5cXyMeta || {},
    alias: promptsrdi7Es5cXyMeta?.alias || [],
    redirect: promptsrdi7Es5cXyMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/prompts.vue").then(m => m.default || m),
    children: [
  {
    name: foldersP9c2HKCUHUMeta?.name ?? "prompts-folders",
    path: foldersP9c2HKCUHUMeta?.path ?? "folders",
    meta: foldersP9c2HKCUHUMeta || {},
    alias: foldersP9c2HKCUHUMeta?.alias || [],
    redirect: foldersP9c2HKCUHUMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/prompts/folders.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93xKhBMVAakPMeta?.name ?? "prompts-folders-id",
    path: _91id_93xKhBMVAakPMeta?.path ?? ":id()",
    meta: _91id_93xKhBMVAakPMeta || {},
    alias: _91id_93xKhBMVAakPMeta?.alias || [],
    redirect: _91id_93xKhBMVAakPMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/prompts/folders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91prompt_id_93HB8laHu18tMeta?.name ?? "prompts-folders-id-prompts-prompt_id",
    path: _91prompt_id_93HB8laHu18tMeta?.path ?? "prompts/:prompt_id()",
    meta: _91prompt_id_93HB8laHu18tMeta || {},
    alias: _91prompt_id_93HB8laHu18tMeta?.alias || [],
    redirect: _91prompt_id_93HB8laHu18tMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/prompts/folders/[id]/prompts/[prompt_id].vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: homeBMM83DknTfMeta?.name ?? "prompts-home",
    path: homeBMM83DknTfMeta?.path ?? "home",
    meta: homeBMM83DknTfMeta || {},
    alias: homeBMM83DknTfMeta?.alias || [],
    redirect: homeBMM83DknTfMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/prompts/home.vue").then(m => m.default || m)
  }
]
  },
  {
    name: registeruUya2x0YcpMeta?.name ?? "register",
    path: registeruUya2x0YcpMeta?.path ?? "/register",
    meta: registeruUya2x0YcpMeta || {},
    alias: registeruUya2x0YcpMeta?.alias || [],
    redirect: registeruUya2x0YcpMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settings0miuIC4XHwMeta?.name ?? undefined,
    path: settings0miuIC4XHwMeta?.path ?? "/settings",
    meta: settings0miuIC4XHwMeta || {},
    alias: settings0miuIC4XHwMeta?.alias || [],
    redirect: settings0miuIC4XHwMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: billingz58L60rCNtMeta?.name ?? "settings-billing",
    path: billingz58L60rCNtMeta?.path ?? "billing",
    meta: billingz58L60rCNtMeta || {},
    alias: billingz58L60rCNtMeta?.alias || [],
    redirect: billingz58L60rCNtMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: checkoute1CvOzCAL5Meta?.name ?? "settings-checkout",
    path: checkoute1CvOzCAL5Meta?.path ?? "checkout",
    meta: checkoute1CvOzCAL5Meta || {},
    alias: checkoute1CvOzCAL5Meta?.alias || [],
    redirect: checkoute1CvOzCAL5Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/settings/checkout.vue").then(m => m.default || m)
  },
  {
    name: importdKt0nECqaZMeta?.name ?? "settings-import",
    path: importdKt0nECqaZMeta?.path ?? "import",
    meta: importdKt0nECqaZMeta || {},
    alias: importdKt0nECqaZMeta?.alias || [],
    redirect: importdKt0nECqaZMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/settings/import.vue").then(m => m.default || m)
  },
  {
    name: indexU1KuRoCYHlMeta?.name ?? "settings",
    path: indexU1KuRoCYHlMeta?.path ?? "",
    meta: indexU1KuRoCYHlMeta || {},
    alias: indexU1KuRoCYHlMeta?.alias || [],
    redirect: indexU1KuRoCYHlMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45successdW2ITcuz81Meta?.name ?? "settings-payment-success",
    path: payment_45successdW2ITcuz81Meta?.path ?? "payment-success",
    meta: payment_45successdW2ITcuz81Meta || {},
    alias: payment_45successdW2ITcuz81Meta?.alias || [],
    redirect: payment_45successdW2ITcuz81Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/settings/payment-success.vue").then(m => m.default || m)
  },
  {
    name: prompt_45library9X38GsCQpZMeta?.name ?? "settings-products-prompt-library",
    path: prompt_45library9X38GsCQpZMeta?.path ?? "products/prompt-library",
    meta: prompt_45library9X38GsCQpZMeta || {},
    alias: prompt_45library9X38GsCQpZMeta?.alias || [],
    redirect: prompt_45library9X38GsCQpZMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/settings/products/prompt-library.vue").then(m => m.default || m)
  },
  {
    name: profileWAOixmIgt6Meta?.name ?? "settings-profile",
    path: profileWAOixmIgt6Meta?.path ?? "profile",
    meta: profileWAOixmIgt6Meta || {},
    alias: profileWAOixmIgt6Meta?.alias || [],
    redirect: profileWAOixmIgt6Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: userspm4R0t5UULMeta?.name ?? "settings-users",
    path: userspm4R0t5UULMeta?.path ?? "users",
    meta: userspm4R0t5UULMeta || {},
    alias: userspm4R0t5UULMeta?.alias || [],
    redirect: userspm4R0t5UULMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/settings/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: folder_45_91permalink_93plZqN5YjxlMeta?.name ?? "shared-folder-permalink",
    path: folder_45_91permalink_93plZqN5YjxlMeta?.path ?? "/shared/folder-:permalink()",
    meta: folder_45_91permalink_93plZqN5YjxlMeta || {},
    alias: folder_45_91permalink_93plZqN5YjxlMeta?.alias || [],
    redirect: folder_45_91permalink_93plZqN5YjxlMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/shared/folder-[permalink].vue").then(m => m.default || m),
    children: [
  {
    name: _91prompt_permalink_93PluQPoDAemMeta?.name ?? "shared-folder-permalink-prompt_permalink",
    path: _91prompt_permalink_93PluQPoDAemMeta?.path ?? ":prompt_permalink()",
    meta: _91prompt_permalink_93PluQPoDAemMeta || {},
    alias: _91prompt_permalink_93PluQPoDAemMeta?.alias || [],
    redirect: _91prompt_permalink_93PluQPoDAemMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/shared/folder-[permalink]/[prompt_permalink].vue").then(m => m.default || m)
  }
]
  },
  {
    name: prompt_45_91permalink_93vr2xV62kTjMeta?.name ?? "shared-prompt-permalink",
    path: prompt_45_91permalink_93vr2xV62kTjMeta?.path ?? "/shared/prompt-:permalink()",
    meta: prompt_45_91permalink_93vr2xV62kTjMeta || {},
    alias: prompt_45_91permalink_93vr2xV62kTjMeta?.alias || [],
    redirect: prompt_45_91permalink_93vr2xV62kTjMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/shared/prompt-[permalink].vue").then(m => m.default || m)
  },
  {
    name: testvaXT3zAa2RMeta?.name ?? "test",
    path: testvaXT3zAa2RMeta?.path ?? "/test",
    meta: testvaXT3zAa2RMeta || {},
    alias: testvaXT3zAa2RMeta?.alias || [],
    redirect: testvaXT3zAa2RMeta?.redirect,
    component: () => import("/opt/render/project/src/modules/chat/pages/test.vue").then(m => m.default || m)
  },
  {
    name: tossCxLO2gLUeMeta?.name ?? "tos",
    path: tossCxLO2gLUeMeta?.path ?? "/tos",
    meta: tossCxLO2gLUeMeta || {},
    alias: tossCxLO2gLUeMeta?.alias || [],
    redirect: tossCxLO2gLUeMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/tos.vue").then(m => m.default || m)
  },
  {
    name: reset_45password4yDKgoYRpdMeta?.name ?? "users-id-reset-password",
    path: reset_45password4yDKgoYRpdMeta?.path ?? "/users/:id()/reset-password",
    meta: reset_45password4yDKgoYRpdMeta || {},
    alias: reset_45password4yDKgoYRpdMeta?.alias || [],
    redirect: reset_45password4yDKgoYRpdMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/users/[id]/reset-password.vue").then(m => m.default || m)
  },
  {
    name: verified_45emailuDy2z2w5l5Meta?.name ?? "users-id-verified-email",
    path: verified_45emailuDy2z2w5l5Meta?.path ?? "/users/:id()/verified-email",
    meta: verified_45emailuDy2z2w5l5Meta || {},
    alias: verified_45emailuDy2z2w5l5Meta?.alias || [],
    redirect: verified_45emailuDy2z2w5l5Meta?.redirect,
    component: () => import("/opt/render/project/src/pages/users/[id]/verified-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailR7tQT2jEbNMeta?.name ?? "users-id-verify-email",
    path: verify_45emailR7tQT2jEbNMeta?.path ?? "/users/:id()/verify-email",
    meta: verify_45emailR7tQT2jEbNMeta || {},
    alias: verify_45emailR7tQT2jEbNMeta?.alias || [],
    redirect: verify_45emailR7tQT2jEbNMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/users/[id]/verify-email.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password6fk3sYDmaFMeta?.name ?? "users-forgot-password",
    path: forgot_45password6fk3sYDmaFMeta?.path ?? "/users/forgot-password",
    meta: forgot_45password6fk3sYDmaFMeta || {},
    alias: forgot_45password6fk3sYDmaFMeta?.alias || [],
    redirect: forgot_45password6fk3sYDmaFMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/users/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93buGp25npjFMeta?.name ?? "users-invite-id",
    path: _91id_93buGp25npjFMeta?.path ?? "/users/invite/:id()",
    meta: _91id_93buGp25npjFMeta || {},
    alias: _91id_93buGp25npjFMeta?.alias || [],
    redirect: _91id_93buGp25npjFMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/users/invite/[id].vue").then(m => m.default || m)
  }
]